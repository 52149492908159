import dayjs from 'dayjs'
import type { Release } from '~/types'

export const useReleaseStore = defineStore('release', () => {
  const releases: Ref<Array<Release>> = ref([])

  const sortedReleases: ComputedRef<Array<Release>> = computed(() =>
    releases.value.sort((a, b) => dayjs(b.date).diff(dayjs(a.date)))
  )
  const latestRelease: ComputedRef<Release | undefined> = computed(() => {
    if (!sortedReleases.value.length) {
      return
    }
    return sortedReleases.value[0]
  })

  async function fetchReleases(cultureCode: string = 'en-GB', force = false) {
    if (releases.value && releases.value.length && !force) {
      return releases.value
    }

    try {
      releases.value = await $hubFetch<Array<Release>>(`/api/v4/releases/${cultureCode}`)
    } catch (error) {
      releases.value = []
    }
  }

  return {
    releases,
    sortedReleases,
    latestRelease,

    fetchReleases
  }
})
